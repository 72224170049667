import { Button as MuiButton, useTheme } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockIcon from '@material-ui/icons/Lock';
import { withStyles } from "@material-ui/styles";
const getIcon = (icon) => {
    switch (icon) {
        case "back":
            return <ArrowBackIcon />;
            break;
        case "next":
            return <ArrowForwardIcon />;
            break;
        case "lock":
            return <LockIcon />;
            break;
        case "email":
            return <MailOutlineIcon />;
            break;
        case "download":
            return <GetAppIcon />;
            break;
        default:
            return <></>;
            break;
    }
}

const styles = (theme) => ({
    gradientStyle: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        background: theme.palette.buttonGradient.secondary,
        color: '#FFF',
        float: 'right',
        borderRadius: "18px",
        "&:disabled": {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            background: theme.palette.buttonGradient.gray,
            border: 0,
            color: '#FFF',
            float: 'right',
            borderRadius: "18px",
        }
    },
    outlinedStyle: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderRadius: "18px",
        "&:disabled": {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            color: '#FFF',
            float: 'right',
            borderRadius: "18px",
        }
    },
});

export const Button = withStyles(styles)(({ type, padding = null, classes, icon, iconPosition, style, variant, children, color, onClick, disabled }) => {
    const theme = useTheme();
    return (
        <MuiButton
            type={type ? type : "button"}
            className={variant === 'outlined' ? classes.outlinedStyle : classes.gradientStyle}
            variant={['outlined', 'contained'].variant ? variant : 'outlined'}
            onClick={onClick}
            disableRipple={false}
            disabled={disabled}
            color={color}
            style={style}
        > {(icon && iconPosition !== 'right') && getIcon(icon)}
            <span style={{ paddingLeft: theme.spacing(padding !== null ? padding : 2), paddingRight: theme.spacing(padding !== null ? padding : 2) }}>{children}</span>
            { (icon && iconPosition === 'right') && getIcon(icon)}
        </MuiButton >)
});

/*

                                style={
                                    {
                                        margin: 3,
                                        borderRadius: 50,
                                        padding: 10,
                                        width: '48%',
                                        lineHeight: 1.2,
                                    }
                                }
                                */