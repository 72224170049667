import { Route, Switch, Redirect } from 'wouter-preact';
import CourseOverview from '/routes/courseOverview';
import ControlCenter from '/routes/controlCenter';
import ChangePassword from '/routes/controlCenter/changePassword';
import Refferals from '/routes/controlCenter/refferals';
import DeleteAccount from '/routes/controlCenter/deleteAccount';
import InvoiceOverview from '/routes/controlCenter/invoiceOverview';
import NotificationOverview from '/routes/controlCenter/notificationOverview';
import Page from '/routes/page';
import Main from '/components/template/main';
import NotFound from '/routes/notFound';
import Login from '/routes/login';
import SignUp from '/routes/signUp';
import Course from '/routes/course';
import CourseModule from '/routes/courseModule';
import CourseModuleItem from '/routes/courseModuleItem';


const Routes = ({ isLoggedIn }) => {
    return (
        <Switch>
            <Route path={"/mitgliederbereich"}>{!isLoggedIn ? <Redirect to={"/login"} /> : <Main><CourseOverview /></Main>}</Route>
            <Route path={"/mitgliederbereich/einstellungen"}>{!isLoggedIn ? <Redirect to={"/login"} /> : <Main headerDirection='left'><ControlCenter /></Main>}</Route>
            <Route path={"/mitgliederbereich/einstellungen/freunde-werben"}>{!isLoggedIn ? <Redirect to={"/login"} /> : <Main headerDirection='left'><Refferals /></Main>}</Route>
            <Route path={"/mitgliederbereich/einstellungen/neues-passwort"}>
                {!isLoggedIn ? <Redirect to={"/login"} /> : <Main headerDirection='left'><ChangePassword /></Main>}
            </Route>
            <Route path={"/mitgliederbereich/einstellungen/account-entfernen"}>
                {!isLoggedIn ? <Redirect to={"/login"} /> : <Main headerDirection='left'><DeleteAccount /></Main>}
            </Route>
            <Route path={"/mitgliederbereich/einstellungen/rechnungen"}>
                {!isLoggedIn ? <Redirect to={"/login"} /> : <Main headerDirection='left'><InvoiceOverview /></Main>}
            </Route>
            <Route path={"/mitgliederbereich/einstellungen/benachrichtigungen"}>
                {!isLoggedIn ? <Redirect to={"/login"} /> : <Main headerDirection='left'><NotificationOverview /></Main>}
            </Route>
            <Route path={"/"} >{isLoggedIn ? <Redirect to={"/mitgliederbereich"} /> : <Redirect to={"/login"} />}</Route>
            <Route path={"/seiten/:contentId"}>{params => <Main headerDirection='right'><Page contentId={params.contentId} /></Main>}</Route>
            <Route path={"/pages/:contentId"}>{params => <Main headerDirection='right'><Page contentId={params.contentId} /></Main>}</Route>
            <Route path={"/login"}>{isLoggedIn ? <Redirect to={"/mitgliederbereich"} /> : <Main headerDirection='right'><Login /></Main>}</Route>
            <Route path={"/anmelden"}>{isLoggedIn ? <Redirect to={"/mitgliederbereich"} /> : <Main headerDirection='right'><Login /></Main>}</Route>
            <Route path={"/sign-up"}><Main headerDirection='right'><SignUp /></Main></Route>
            <Route path={"/registrieren"}><Main headerDirection='right'><SignUp /></Main></Route>
            <Route path={"/registrieren/schritt-:step"}>{params => <Main headerDirection='right'><SignUp step={params.step} /></Main>}</Route>
            <Route path={"/e/:coupon"}>{params => <Main headerDirection='right'><SignUp coupon={params.coupon} /></Main>}</Route>
            <Route path={"/registrieren/:product"}>{params => <Main headerDirection='right'><SignUp product={params.product} /></Main>}</Route>
            <Route path={"/registrieren/schritt-:step/:product"} ><Main headerDirection='right'>{params => <SignUp step={params.step} product={params.product} />}</Main></Route>
            <Route path={"/buchen"}><Main headerDirection='right'><SignUp /></Main></Route>
            <Route path={"/buchen/schritt-:step"}>{params => <Main headerDirection='right'><SignUp step={params.step} /></Main>}</Route>
            <Route path={"/buchen/:product"}>{params => <Main headerDirection='right'><SignUp product={params.product} /></Main>}</Route>
            <Route path={"/buchen/schritt-:step/:product"} ><Main headerDirection='right'>{params => <SignUp step={params.step} product={params.product} />}</Main></Route>
            <Route path={"/mitgliederbereich/kurse/:courseId"} >{!isLoggedIn ? <Redirect to={"/login"} /> : params => <Main headerDirection='right'><Course courseId={params.courseId} /></Main>}</Route>
            <Route path={"/mitgliederbereich/kurse/:courseId/module/:moduleId"} >{!isLoggedIn ? <Redirect to={"/login"} /> : params => <Main headerDirection='right'><CourseModule courseId={params.courseId} moduleId={params.moduleId} /></Main>}</Route>
            <Route path={"/mitgliederbereich/kurse/:courseId/module/:moduleId/item/:itemId"} >{!isLoggedIn ? <Redirect to={"/login"} /> : params => <Main headerDirection='right'><CourseModuleItem courseId={params.courseId} moduleId={params.moduleId} itemId={params.itemId} /></Main>}</Route>
            <Route path={"/mitgliederbereich/kurse/:courseId/module/:moduleId/homework/:itemId"} >{!isLoggedIn ? <Redirect to={"/login"} /> : params => <Main headerDirection='right'><CourseModuleItem courseId={params.courseId} moduleId={params.moduleId} itemId={params.itemId} /></Main>}</Route>
            <Route path={"/seite-nicht-gefunden"} component={NotFound}></Route>
            <Route component={NotFound}></Route>
        </Switch >
    );
};


export default Routes;
