import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useLocation } from "wouter-preact";
import {
    AppBar, Toolbar
} from '@material-ui/core';
import { useUserState } from '/lib/states';
import { HeaderIcon } from '/components/common';


const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        height: '40px',
        background: theme.palette.headerGradient.primary,
        position: 'fixed',
        left: '50%',
        top: '0%',
        transform: 'translateX(-50%)',
        maxWidth: '640px',
        width: '100%',
        [theme.breakpoints.up('xs')]: {
            maxWidth: '640px',
        },
    },
    sectionDesktop: {
        position: 'absolute',
        right: '0',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    svg: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        marginBottom: -1,
        ['@media (min-width:760px)']: { // eslint-disable-line no-useless-computed-key
            height: "20px !important",
        },
        ['@media (max-width:760px)']: { // eslint-disable-line no-useless-computed-key
            height: '5vw',
        },
    },
}));

export default function Header({ direction }) {
    const classes = useStyles();
    const theme = useTheme();
    const [location, setLocation] = useLocation();
    const { isLoggedIn } = useUserState();
    return (
        <>
            <AppBar id="trainerio-header" position="fixed" className={classes.appBar} style={{ height: '40px' }} elevation={0} align="center">
                <svg className={classes.svg} style={{ height: '5vw' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
                    <polygon fill={theme.palette.background.default} points={(direction === 'left' ? '0' : '100') + ",0 0,100 100,100"} />
                </svg>
                <Toolbar
                    disableGutters
                    style={{
                        margin: 'auto', height: '20px', position: 'relative',
                    }}
                />
                {(['/mitgliederbereich', '/mitgliederbereich/', '/mitgliederbereich/kurse'].includes(location) && isLoggedIn)
                    && (
                        <Toolbar disableGutters className={classes.sectionDesktop}>
                            <HeaderIcon variant="user" onClick={() => setLocation("/mitgliederbereich/einstellungen")} />
                        </Toolbar>
                    )}
            </AppBar>
        </>
    );
}
