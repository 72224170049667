import { commonService } from './common';

const createPaymentIntent = (data) => commonService.request('POST', '/orders/create-payment-intent', { data }, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

const getPaymentMethodsForCustomer = () => commonService.request('GET', '/orders/payment-methods', {}, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

const checkVoucher = (data) => commonService.request('POST', '/orders/voucher', { data }, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

const createOrderIntent = (data) => commonService.request('POST', '/orders', { data }, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);
const completeOrder = (orderId, data) => commonService.request('PUT', `/orders/${orderId}`, { data }, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);
const checkOrderStatus = (orderId, externalOrderId) => commonService.request('GET', `/orders/${orderId}/${externalOrderId}`, {}, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

const getOrders = () => commonService.request('GET', '/orders', {}, commonService.HEADER_TYPES.AUTHORIZED).then((response) => response);

const sendInvoice = (orderId) => commonService.request('POST', `/orders/${orderId}/invoice`, {}, commonService.HEADER_TYPES.AUTHORIZED);
const getInvoice = (orderId) => commonService.request('GET', `/orders/${orderId}/invoice`, {}, commonService.HEADER_TYPES.AUTHORIZED);

const sendCertificate = (orderId) => commonService.request('POST', `/orders/${orderId}/certificate`, {}, commonService.HEADER_TYPES.AUTHORIZED);
const getCertificate = (orderId) => commonService.request('GET', `/orders/${orderId}/certificate`, {}, commonService.HEADER_TYPES.AUTHORIZED);

export const orderService = {
    createPaymentIntent,
    getPaymentMethodsForCustomer,
    checkVoucher,
    createOrderIntent,
    completeOrder,
    getOrders,
    sendInvoice,
    getInvoice,
    getCertificate,
    sendCertificate,
    checkOrderStatus,
};
