import React, { useState, useEffect } from 'react';
import { userService } from '/services';
import {
    useMediaQuery, Fab, IconButton, Typography, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, FormHelperText, Link, Box
} from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import ReactInputVerificationCode from 'react-input-verification-code';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useTheme } from '@material-ui/core/styles';
import { DialogTitle, Button } from '/components/common';
import { useUserState } from '/lib/states';
import { HeaderIcon, HeaderIconGroup } from '/components/common';
import { useLocation } from 'wouter-preact';

export default function ChangePassword(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [username, setUsername] = useState('');
    const [errors, setErrors] = useState({});
    const [showSuccessMessage, setSuccessMessage] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [verificationCode, setVerificationCode] = React.useState(0);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState('');

    const [location, setLocation] = useLocation();
    const { user, logout } = useUserState();
    const [counter, setCounter] = React.useState(10);
    const handleClose = () => {
        setUsername('');
        setSuccessMessage(false);
        props.close();
    };
    const handleChange = (event) => {
        setUsername(event.target.value);
        setErrors({});
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setErrors({});
    };
    const submitMailAddress = () => userService.changePassword().then(() => {
        setSuccessMessage(true);
    }).catch((err) => {
        setErrors([{ field: 'username', message: err.message }]);
    });

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const verifyCode = () => userService.validatePasswordLink({ verificationToken: verificationCode }).then((validationResult) => {

        if (validationResult.status === 'success') {
            setErrors({});
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            setErrors({ ...errors, verificationCode: 'Der eingegbene Code ist nicht korrekt' });
        }
        setSuccessMessage(true);
    }).catch((err) => {
        setErrors({ ...errors, verificationCode: 'Der eingegbene Code ist nicht korrekt' });
    });

    const resetPassword = () => userService.resetPassword({ verificationToken: verificationCode, password }).then(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSuccessMessage(true);
    }).catch((err) => {
        const error = { newPassword: 'Der eingegbene Code ist nicht korrekt' };
        setErrors(error);
    });

    useEffect(() => {
        setUsername(props.username);
    }, [props.username]);

    const { t } = useTranslation();

    const handleNext = () => {
        switch (steps[activeStep].slug) {
            case 'STEP1':
                submitMailAddress();
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setCounter(10);
                break;
            case 'STEP2':
                verifyCode();
                break;
            case 'STEP3':
                resetPassword();
                break;
            case 'STEP4':
                setLocation('/mitgliederbereich/einstellungen');
                break;
            default:
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
        }
    };

    const handleBack = () => {
        setCounter(10);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const steps = [{
        slug: 'STEP1',
        component:
            <>
                Aus Sicherheitsgründen möchten wir bestätigen, dass Du auch der Eigentümer dieses Accounts und damit der E-Mail Adresse <b>{user.username}</b> bist.
                <br /><br />
                Wir werden im nächsten Schritt eine E-Mail zur Verifizierung versenden. Wir bitten Dich den Anweisungen darin zu folgen.
                <br />
                <br />
                <br />
                <br />
            </>,
        title: 'Passwort ändern',
        buttonText: 'E-Mail Schicken',
    },
    {
        slug: 'STEP2',
        component:
            <>
                1. Schritt geschafft!
                <br /><br />
                Bitte öffne nun die E-Mail, die wir soeben an Dich versandt haben und gib die darin enthaltene vierstellige Zahl hier ein:
                {' '}
                <b>{username}</b>
                {' '}
gesendet haben.
                <br />
                {' '}
                <br />
                <div align="center">
                    <ReactInputVerificationCode onChange={(value) => setVerificationCode(value)} value={verificationCode} autoFocus />
                    <FormHelperText>
                        {errors.verificationCode && errors.verificationCode}
                    </FormHelperText>
                </div>
                <br />
                <br />
                {counter === 0
                    ? (
                        <div>
                            E-Mail nicht angekommen oder zu spät gewesen? Bitte überprüfe auch Deinen Spam-Ordner. Ansonsten:
                            <Link underline={'hover'} onClick={() => {
                                submitMailAddress();
                                setCounter(10);
                            }}
                            > Erneut zusenden</Link>.
                        </div>
                    )

                    : (
                        <div>
                            Bitte warte noch
                            {' '}
                            {counter}
                            {' '}
                Sekunden auf den Erhalt des Codes.
                        </div>
                    )}
                <br />
                <br />
            </>,
        title: 'Passwort ändern',
        buttonText: 'Weiter',
    },
    {
        slug: 'STEP3',
        component:
            <>
                Prima! Bitte erstelle nun ein neues Passwort. <br /> <br />
                Tipp: Dein Passwort ist besonders sicher, wenn es mindestens 8 Zeichen hat, idealerweise eine Mischung aus Groß- und Kleinbuchstaben, Ziffern und Sonderzeichen (z. B. *, %, §, ?).
                <br />
                <br />
                <div align="center">
                    <FormControl variant="outlined" required fullWidth error={!!errors.password}>
                        <InputLabel htmlFor="password">Passwort</InputLabel>
                        <OutlinedInput
                            fullWidth
                            id="password"
                            required
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={handlePasswordChange}
                            onFocus={() => setErrors({})}
                            endAdornment={(
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )}
                            name="password"
                            label={t('signup.loginDetails.password')}
                            autoComplete="new-password"
                        />
                        <FormHelperText error>
                            {errors.password && errors.password}
                        </FormHelperText>
                    </FormControl>
                </div>
            </>,
        title: 'Passwort ändern',
        buttonText: 'Passwort festlegen',
    },
    {
        slug: 'STEP4',
        component:
            <>
                <br />
                <div align="center">
                    Sehr gut. Dein Passwort wurde geändert...
                </div>
                <br />
            </>,
        title: 'Passwort geändert!',
        buttonText: 'OK',
    }];

    React.useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    return (
        <Box m={2} >
            <HeaderIconGroup>
                <HeaderIcon variant="back" onClick={() => history.back()} />
            </HeaderIconGroup>
            <Typography variant="h6">{steps[activeStep].title}</Typography>
            <br />
            <div>{steps[activeStep].component}</div>
            <div style={{ float: 'right' }}>
                <Button onClick={handleNext} color="primary" fullWidth variant="next" >
                    {steps[activeStep].buttonText}
                </Button>
            </div>
        </Box>
    );
}
