/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'preact/hooks';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Typography,
    Fab,
    CardContent,
    Card,
    Divider,
    Box,
    Dialog,
    IconButton,
    ListItemText,
    ListItem,
    List,
    Slide,
    DialogContent,
    DialogContentText,
    DialogActions,
    ListItemIcon,
} from '@material-ui/core';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useTranslation } from 'react-i18next';
import { useUserState } from '/lib/states';
import { HeaderIcon, HeaderIconGroup, Button, DialogTitle, Switch } from '/components/common';

import { forwardRef } from "preact/compat";
import { useLocation } from 'wouter-preact';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { orderService } from '/services';
import * as dayjs from 'dayjs'
import { saveAs } from 'file-saver';

const useStyles = makeStyles((theme) => ({
    cardWithoutBackground: {
        backgroundColor: theme.palette.background.default,
        marginTop: '10px'
    },
    cardWithBackground: {
        borderTop: '1px solid #EBEBF0',
        borderBottom: '1px solid #EBEBF0',
        marginTop: '10px'
    },
}));

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);


const InvoiceOverview = (props) => {
    const theme = useTheme();

    const classes = useStyles();
    const { t } = useTranslation();
    const { user, userTheme, logout, setTheme, updatePreferences } = useUserState();

    const [sendingDoneDialogOpen, setSendingDoneDialogOpen] = useState(false);

    const [state, setState] = useState({
        MODULE_ENABLED: user.UserProfile.consent_notifications && user.UserProfile.consent_notifications.hasOwnProperty("MODULE_ENABLED") ? user.UserProfile.consent_notifications.MODULE_ENABLED.isActive : false,
        COURSE_REMINDER: user.UserProfile.consent_notifications && user.UserProfile.consent_notifications.hasOwnProperty("COURSE_REMINDER") ? user.UserProfile.consent_notifications.COURSE_REMINDER.isActive : false,
        COURSE_ALERT: user.UserProfile.consent_notifications && user.UserProfile.consent_notifications.hasOwnProperty("COURSE_ALERT") ? user.UserProfile.consent_notifications.COURSE_ALERT.isActive : false,
        QUARTERLY_NEWSLETTER: user.UserProfile.consent_notifications && user.UserProfile.consent_notifications.hasOwnProperty("QUARTERLY_NEWSLETTER") ? user.UserProfile.consent_notifications.QUARTERLY_NEWSLETTER.isActive : false,
        FREQUENT_NEWSLETTER: user.UserProfile.consent_notifications && user.UserProfile.consent_notifications.hasOwnProperty("FREQUENT_NEWSLETTER") ? user.UserProfile.consent_notifications.FREQUENT_NEWSLETTER.isActive : false,
    });


    const handleChange = (event) => {
        const newState = { ...state, [event.target.name]: event.target.checked };
        setState(newState);
        updatePreferences({ consent_notifications: newState });
    };

    const [location, setLocation] = useLocation();
    return (
        <>
            <HeaderIconGroup>
                <HeaderIcon variant="back" onClick={() => setLocation('/mitgliederbereich/einstellungen')} />
            </HeaderIconGroup>
            <Box style={{ padding: theme.spacing(1) }} elevation={0}>
                <Typography variant="h6">
                    Benachrichtigungen
                </Typography>
                <Typography variant="body2">
                    Rein funktionale Benachrichtigungen per E-Mail können wir nicht ausschalten, wie z.B. bei Passwort vergessen, Rechnung versenden oder Kontaktaufnahme von Dir.
                    <br />
                    Alle weiteren Benachrichtigungen kannst Du hier einstellen:
                </Typography>
                <br />
            </Box>
            <Card elevation={0} className={classes.cardWithBackground} square>
                <CardContent style={{ padding: theme.spacing(1) }}>
                    <Typography variant="subtitle2">Erinnerung an Deine Übungen und Module</Typography>
                    <List dense>
                        <ListItem >
                            <ListItemText primary="Neues Modul freigeschaltet" />
                            <Switch checked={state.MODULE_ENABLED} onChange={handleChange} name="MODULE_ENABLED" />
                        </ListItem>
                        <Divider />
                        <ListItem >
                            <ListItemText primary="Regelerinnerung an Übungen, oder Ergebnisbenachrichtigungen" />
                            <Switch checked={state.COURSE_REMINDER} onChange={handleChange} name="COURSE_REMINDER" />
                        </ListItem>
                        <Divider />
                        <ListItem >
                            <ListItemText primary="Erinnerung bei Verzögerungen, die ggf. zu Zertifikatverlust führen könnten" />
                            <Switch checked={state.COURSE_ALERT} onChange={handleChange} name="COURSE_ALERT" />
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
            <br />
            <Card elevation={0} className={classes.cardWithoutBackground} square>
                <CardContent style={{ padding: theme.spacing(1) }}>
                    <Typography variant="subtitle2">Angebote</Typography>
                    <Typography variant="body2">Trainerios Gesundheitsangebot wächst stetig. Und es gibt immer wieder neue Kursangebote (auch kostenfrei oder vergünstigt)</Typography>
                    <List dense>
                        <ListItem >
                            <ListItemText primary="Eine Zusammenfassung alle 3 Monate" />
                            <Switch checked={state.QUARTERLY_NEWSLETTER} onChange={handleChange} name="QUARTERLY_NEWSLETTER" />
                        </ListItem>
                        <Divider />
                        <ListItem >
                            <ListItemText primary="Angebote, sobald verfügbar. Ich möchte keine Vergünstigung verpassen." />
                            <Switch checked={state.FREQUENT_NEWSLETTER} onChange={handleChange} name="FREQUENT_NEWSLETTER" />
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
            <br />
            <Card elevation={0} className={classes.cardWithBackground} square>
                <CardContent style={{ padding: theme.spacing(1) }}>
                    <Typography variant="body2">Falls Du noch mal nachschlagen möchtest, wie gut wir mit Deinen Daten umgehen:<br />Unsere Datenschutzbestimmungen</Typography>
                </CardContent>
            </Card>
            <br />
            <Dialog
                open={sendingDoneDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setSendingDoneDialogOpen(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Rechnung versandt</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Die Rechnung wurde erfolgreich an deine Email-Adresse geschickt.
                     </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSendingDoneDialogOpen(false)} color="primary">OK</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default InvoiceOverview;
