import { createState, useState } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';

const onboardingState = createState({
    activeStep: 0,
    flow: { steps: [] },
    values: {}
})

export function useOnboardingState() {
    const state = useState(onboardingState);
    if (typeof window !== 'undefined') {
        state.attach(Persistence('trainerio-onboarding'));
    }
    return ({
        setActiveFlow(newFlow) {
            state.set(oldState =>
            ({
                ...oldState,
                flow: newFlow,
            }));
        },
        setOnboardingValues(newValues) {
            state.set(oldState =>
            ({
                ...oldState,
                values: newValues,
            }));
        },
        setActiveStep(activeStep) {
            state.set(oldState =>
            ({
                ...oldState,
                activeStep: Number.isNaN(activeStep) ? 0 : activeStep,
            }));
        },
        resetOnboardingState() {
            state.set(() =>
            ({
                activeStep: 0,
                flow: { steps: [] },
                values: {}
            }));
        },
        get onboardingValues() {
            return JSON.parse(JSON.stringify(state.values.get()));
        },
        get activeFlow() {
            return JSON.parse(JSON.stringify(state.flow.get()));
        },
        get activeStep() {
            return Number.parseInt(state.activeStep.get(), 10);
        },
    })
}