import { commonService } from './common';

const getProducts = () => commonService.request('GET', 'public/products', {}, commonService.HEADER_TYPES.PUBLIC).then((response) => response);

const getProductById = (productId) => commonService.request('GET', `public/products/${productId}`, {}, commonService.HEADER_TYPES.PUBLIC).then((response) => response);

export const productService = {
    getProducts,
    getProductById,
};
