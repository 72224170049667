import { commonService } from './common';

const getUserCourses = () => commonService.request('GET', '/user-courses', {}, commonService.HEADER_TYPES.AUTHORIZED);
const getAvailableCourses = () => commonService.request('GET', '/user-courses/available', {}, commonService.HEADER_TYPES.AUTHORIZED);
const bookCourse = (courseId) => commonService.request('POST', `/user-courses/${courseId}`, {}, commonService.HEADER_TYPES.AUTHORIZED);

const unsubscribeCourse = (courseId) => commonService.request('PUT', `/user-courses/${courseId}/unsubscribe`, {}, commonService.HEADER_TYPES.AUTHORIZED);

const getCourseById = (courseId) => commonService.request('GET', `/user-courses/${courseId}`, {}, commonService.HEADER_TYPES.AUTHORIZED);

const getCourseModuleById = (courseId, moduleId) => commonService.request('GET', `/user-courses/${courseId}/modules/${moduleId}`, {}, commonService.HEADER_TYPES.AUTHORIZED);

const sendDocument = (courseId, moduleId, itemId) => commonService.request('POST', `/user-courses/${courseId}/modules/${moduleId}/items/${itemId}/document`, {}, commonService.HEADER_TYPES.AUTHORIZED);

const trackCourseAction = (courseId, data) => commonService.request('POST', `/user-courses/${courseId}/track`, {
    data,
}, commonService.HEADER_TYPES.AUTHORIZED);

const trackCourseModuleAction = (courseId, moduleId, data) => commonService.request('POST', `/user-courses/${courseId}/modules/${moduleId}/track`, {
    data,
}, commonService.HEADER_TYPES.AUTHORIZED);

const trackCourseModuleItemAction = (courseId, moduleId, itemId, data) => commonService.request('POST', `/user-courses/${courseId}/modules/${moduleId}/items/${itemId}/track`, {
    data,
}, commonService.HEADER_TYPES.AUTHORIZED);

export const courseService = {
    getUserCourses,
    getAvailableCourses,
    getCourseById,
    bookCourse,
    unsubscribeCourse,
    getCourseModuleById,
    trackCourseModuleItemAction,
    trackCourseModuleAction,
    trackCourseAction,
    sendDocument,
};
