import { Paper, useMediaQuery, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Header from '/components/template/header';
import Help from '/components/common/help';
import { Link } from 'wouter-preact';

const useStyles = makeStyles((theme) => {

    return ({
        app: {
            background: theme.palette.background.default,
            alignSelf: 'center',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            [theme.breakpoints.up('xs')]: {
                maxWidth: '640px'
            },
            [theme.breakpoints.up('sm')]: {
                marginBottom: '150px'
            },
            position: 'relative',
            overflowY: 'auto',
            zIndex: 0,
        },
        content: {
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            flexDirection: 'column',
            flex: 2,
        },
        helpSection: {

            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            height: '100px',
        },
        footerMobile: {
            textAlign: 'center',
            position: 'fixed',
            bottom: '0',
            width: '100%',
            maxWidth: 640
        },
        header: {
            position: 'relative',
            minHeight: '65px',
            zIndex:0, 
            alignSelf: 'center',
        },

        phoneScreen: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'hidden',

        },

    })
});

const Main = ({ children, headerDirection, headerColor }) => {
    const classes = useStyles();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <div className={classes.phoneScreen}>
            <Paper className={classes.app} elevation={0} square>
                <div className={classes.header}>
                    <Header direction={headerDirection} color={headerColor} />
                </div>
                <section className={classes.content}>
                    {children}
                </section>
                {matches && <div style={{ background: theme.palette.gradient.primary, textAlign: 'center', marginBottom: '100px' }}>
                    <Typography variant="overline" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} href="/seiten/teilnahmebedingungen">Teilnahmebedingungen</Link>
                        <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} href="/seiten/datenschutz">Datenschutz</Link>
                        <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} href="/seiten/agb">AGB</Link>
                        <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} href="/seiten/impressum">Impressum</Link>
                    </Typography>
                    <a style={{ color: '#FFFFFF', textDecoration: 'none', textAlign: 'center' }} href="https://www.trainerio.com" target="_blank">© {(new Date()).getFullYear()} Trainerio</a>
                </div>}
                <Paper elevation={5} className={classes.footerMobile} square>
                    <div className={classes.helpSection}>
                        <div><Help /></div>
                    </div>
                    {!matches && <div style={{ background: theme.palette.gradient.primary, textAlign: 'center' }}>
                        <Typography variant="overline" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} href="/seiten/teilnahmebedingungen">Teilnahmebedingungen</Link>
                            <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} href="/seiten/datenschutz">Datenschutz</Link>
                            <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} href="/seiten/agb">AGB</Link>
                            <Link style={{ color: '#FFFFFF', textDecoration: 'none' }} href="/seiten/impressum">Impressum</Link>
                        </Typography>
                        <a style={{ color: '#FFFFFF', textDecoration: 'none', textAlign: 'center' }} href="https://www.trainerio.com" target="_blank">© {(new Date()).getFullYear()} Trainerio</a>
                    </div>}
                </Paper>

            </Paper>
        </div>
    )
};

export default Main;
