/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'preact/hooks';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Typography,
    Fab,
    CardContent,
    Card,
    Divider,
    Box,
    Dialog,
    IconButton,
    ListItemText,
    ListItem,
    List,
    Tooltip,
    Slide,
    Grid,
    DialogContent,
    DialogContentText,
    DialogActions,
    InputAdornment,
    TextField,
    ListItemIcon,
    useMediaQuery,
    FormHelperText
} from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useTranslation } from 'react-i18next';
import { useUserState } from '/lib/states';
import { HeaderIcon, HeaderIconGroup, Button, DialogTitle, Switch } from '/components/common';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { forwardRef } from "preact/compat";
import { useLocation } from 'wouter-preact';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { orderService, userService } from '/services';
import * as dayjs from 'dayjs'
import { saveAs } from 'file-saver';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import parse from 'html-react-parser';
import { useContentState } from '/lib/states';
import { SnackbarProvider, useSnackbar } from 'notistack';


const useStyles = makeStyles((theme) => ({
    cardWithoutBackground: {
        backgroundColor: theme.palette.background.default,
        marginTop: '10px'
    },
    cardWithBackground: {
        borderTop: '1px solid #EBEBF0',
        borderBottom: '1px solid #EBEBF0',
        marginTop: '10px'
    },
    code: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        border: '1px solid #EBEBF0',

    },
    codeCopied:
    {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        border: '1px solid #EBEBF0',
    }
}));

const Refferals = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const { enqueueSnackbar } = useSnackbar();

    const classes = useStyles();
    const { t } = useTranslation();
    const { user, userTheme, logout, setTheme, updatePreferences } = useUserState();


    const [copiedCode, setCopiedCode] = useState(false);
    const [copiedUrl, setCopiedUrl] = useState(false);
    const [voucher, setVoucher] = useState(false);
    const [refferals, setRefferals] = useState([]);
    const [wallet, setWallet] = useState({});
    const [payoutValueError, setPayoutValueError] = useState("");

    const [payoutValue, setPayoutValue] = useState("0.00");


    const [payoutDialogOpen, setPayoutDialogOpen] = useState(false);
    const [termsDialogOpen, setTermsDialogOpen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isClaimSuccessful, setIsClaimSuccessful] = useState(false);
    const { getContent } = useContentState();

    const [terms, setTerms] = useState("");

    const init = async () => {
        const content = await getContent("alalal");
        setTerms(content.body.replace(/(<!--.*?-->)/g, ''));
        await userService.getVoucher('refferal').then((dbVoucher) => setVoucher(dbVoucher.code));
        await userService.getRefferals().then((dbRefferals) => setRefferals(dbRefferals));
        await userService.getWallet().then((dbWallet) => setWallet(dbWallet));

    }

    const acceptRules = async () => {
        await userService.updateVoucher('refferal').then((dbVoucher) => setVoucher(dbVoucher.code));
    }

    const handleClose = () => {
        setPayoutDialogOpen(false);
        termsDialogOpen(false);
    };

    const handleClaimPayout = async () => {
        setIsProcessing(true);
        await userService.updateWallet({payout: payoutValue}).then((dbWallet) => {
            setIsClaimSuccessful(true);
            setIsProcessing(false);
            init();
        }).catch((err) => {
            setIsProcessing(false);
        });
    }

    const handlePayoutValue = (event) => {
        let tempValue = event.target.value;
        const numberOfDecimals = tempValue.toString().split(".").length > 1 ? tempValue.toString().split(".")[1].length || 0 : 0;
        if (numberOfDecimals > 2) {
            tempValue = (tempValue * Math.pow(10, numberOfDecimals)) / 100;
        }
        if (numberOfDecimals < 2) {
            tempValue = Number.parseFloat(tempValue).toFixed(2).toString();
        }
        if (tempValue > wallet.available) {
            setPayoutValueError("Der gewählte Betrag übersteigt das Guthaben");
        } else {
            setPayoutValueError("");
        }

        setPayoutValue(tempValue);
    };

    useEffect(() => {
        init();
    }, []);


    const [location, setLocation] = useLocation();
    return (
        <>
            <HeaderIconGroup>
                <HeaderIcon variant="back" onClick={() => setLocation('/mitgliederbereich/einstellungen')} />
            </HeaderIconGroup>
            <Box style={{ padding: theme.spacing(1) }} elevation={0}>
                <Typography variant="h6">
                    Kunden werben Kunden-Programm
                </Typography>

                <Card elevation={0} className={classes.cardWithBackground} square>
                    <CardContent style={{ padding: theme.spacing(1) }}>
                        <Typography variant="subtitle2">Dein Konto</Typography>
                        <br />
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '30%', textAlign: 'center' }}>
                                <Typography variant="body2">Ausgezahlt</Typography>
                                <Typography variant="h5">{(wallet.claimed ? wallet.claimed : 0).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</Typography>

                            </div>
                            <div style={{ width: '40%', textAlign: 'center' }}>
                                <Typography variant="body2">Verfügbar</Typography>
                                <Typography variant="h4" color="primary">{(wallet.available ? wallet.available : 0).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</Typography>
                            </div>
                            <div style={{ width: '30%', textAlign: 'center' }}>
                                <Typography variant="body2">Ausstehend</Typography>
                                <Typography variant="h5">{(wallet.pending ? wallet.pending : 0).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</Typography>

                            </div>
                        </div>
                        <br />
                        {voucher && <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button disabled={(!wallet.available || wallet.available === 0)} onClick={() => { setIsClaimSuccessful(false); setPayoutDialogOpen(true) }}>Jetzt Auszahlen</Button>
                        </div>}
                    </CardContent>
                </Card>
                <br />
                <Typography variant="body2">
                    Freunden eines der wichtigsten Dinge im Leben empfehlen und gleichzeit auch noch selbst was daran verdienen, besser gehts nicht.
                    <br /><br />
                    Für jede erfolgreiche Anmeldung erhälst du <b>10,00 €</b> auf dein virtuellse Konto. Den verfügbaren betrag kannst du dir dann als Amazon Gutschein auszahlen lassen.
                    <br /><br />

                    {voucher ? (
                        <>
                            Erzähle gutes über unseren Kurs und lasse deine Freune folgenden Gutschein-Code bei der Registrierung eingeben:
                            <br /><br />
                            <div className={copiedCode ? classes.codeCopied : classes.code} >
                                <div className={classes.codeText}>{voucher}</div>
                                <CopyToClipboard text={voucher} onCopy={() => {
                                    setCopiedCode(true); enqueueSnackbar('Code in die Zwischenablage kopiert', {
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }, autoHideDuration: 1000
                                    });
                                }}>
                                    <IconButton>{copiedCode ? <AssignmentTurnedInIcon /> : <AssignmentIcon />}</IconButton>
                                </CopyToClipboard>
                            </div>

                            <br />
                            Oder noch einfacher mit dem direkten Link zur Registrierung:
                            <br />
                            <div className={copiedUrl ? classes.codeCopied : classes.code} >
                                <div className={classes.codeText}>https://kurse.trainerio.local/e/{voucher}</div>
                                <CopyToClipboard text={"https://kurse.trainerio.local/e/" + voucher} onCopy={() => {
                                    setCopiedUrl(true); enqueueSnackbar('URL in die Zwischenablage kopiert', {
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }, autoHideDuration: 1000
                                    });
                                }}>
                                    <IconButton>{copiedUrl ? <AssignmentTurnedInIcon /> : <AssignmentIcon />}</IconButton>
                                </CopyToClipboard>
                            </div>
                        </>
                    ) : (
                        <>

                            {parse(terms)}
                            Bevor es los gehen kann musst du folgende Teilnahmebedingungen für das "Kunden werben Kunden-Programm" akzeptieren.<br /><br />

                            <Typography variant="h6">
                                Kunden werben Kunden-Programm
                            </Typography>

                            <br />
                            Es gelten die Datenschutzerklärung und die AGB von Trainerio. Alle genannten Preise sind Bruttopreise, außer anders angegeben.<br /><br />
                            <b>Teilnahmebedingungen</b><br />
                            Trainerio bietet seinen Kunden unter bestimmten Bedingungen an, Gutschriften durch die erfolgreiche Werbung von Trainerio Neukunden zu erhalten.
                            Der Werbelink kann dann vom Werbenden als Nachricht, E-Mail oder via Social Media geteilt werden.
                            <br />
                            Der geworbene Neukunde bekommt einen Rabatt in Höhe von 5 Euro, wenn er sich über
                            einen gültigen Werbelink anmeldet. Der Werbende bekommt eine Gutschift in Höhe von 10
                            Euro, sobald der geworbene Neukunde für mehr als 14 Tage den Kurs benutzt.
                            <br />
                            Die Gutschriften können vom Webenden einzelnd oder gesammelt in Amazon Gutscheine umgewandelt werden.<br /><br />
                            <b>Voraussetzungen</b><br />
                            <ol>
                                <li>Gutschrift berechtigt sind nur Kurse unter (https://kurse.trainerio.com/buchen).</li>
                                <li>Als Werbender kann am Kunden werben Kunden-Programm nur ein aktiver Nutzer der Kurse teilnehmen.</li>
                                <li>Sowohl der Werbende als auch der Geworbene müssen das 18. Lebensjahr vollendet haben.</li>
                                <li>Ehemalige Kunden, deren Vertrag seitens Trainerio gekündigt wurden, sind vom Kunden werben Kunden-Programm ausgeschlossen.</li>
                                <li>Eine andere Auszahlung der Gutschrift als die Umwandlung in einen Amazon Gutschein erfolgt nicht. Die Gutschrift ist ebenfalls nicht auf Dritte übertragbar.</li>
                                <li>Eine vom Werbenden erhaltene Gutschrift verfällt, wenn diese nicht innerhalb eines 12-monatigen Zeitraums in einen Amazon Gutschein umgewandelt wird</li>
                                <li>EWerbenden Kunden ist es sowohl untersagt, ihren Werbelink über Massenemails an unbekannte Empfänger oder über automatisierte Systeme/Bots zu verteilen als auch bezahlte Werbung auf Ihren personalisierten Werbelink zu schalten.</li>
                                <li>Bei Missbrauchsverdacht kann der entsprechende Kunde von der Teilnahme am Kunden werben Kunden-Programm ausgeschlossen werden, indem der für ihn generierte Werbelink ungültig gemacht wird. Gutschriften werden damit (auch rückwirkend) ausgeschlossen. Die Gutschriften sind  eine freiwillige Leistung von Trainerio, ein Rechtsanspruch besteht nicht. Trainerio muss einen Missbrauchsverdacht nicht begründen.</li>
                                <li>Trainero behält sich vor, das Kunden werben Kunden-Programm zu jedem Zeitpunkt ohne Vorankündigung und ohne Angabe von Gründen zu ändern, abzubrechen oder zu beenden.</li>
                                <li>Mit der Teilnahme am Kunden werben Kunden-Programm erkennt der Werbende diese Teilnahmebedingungen an.</li>
                            </ol>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={() => acceptRules()}>Teilnahmebedingungen akzeptieren</Button>
                            </div>
                        </>
                    )}
                </Typography>
            </Box>

            {voucher && (<Card elevation={0} className={classes.cardWithBackground} square>
                <CardContent style={{ padding: theme.spacing(1) }}>
                    <Typography variant="subtitle2">Deine Einladungen</Typography>
                    <Typography variant="body2">Aus Datenschutzgründen wird nur das Datum der Registrierung angezeigt.</Typography>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Registriert</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Freigabe</TableCell>
                                    <TableCell align="right">Betrag</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {refferals.map((row, index) => (
                                    <TableRow key={"row_" + index}>
                                        <TableCell align="center">{dayjs(row.created_on).format('DD.MM.YYYY')}</TableCell>
                                        <TableCell align="center" style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>{row.status === "AVAILABLE" ? <Tooltip title="Verfügbar"><CheckCircleOutlineIcon color="primary" /></Tooltip> : <Tooltip title="Ausstehend"><QueryBuilderIcon color="secondary" /></Tooltip>}</TableCell>
                                        <TableCell align="center">{row.unlocked_on ? dayjs(row.unlocked_on).format('DD.MM.YYYY') : dayjs(row.created_on).add(14, 'day').format('DD.MM.YYYY')}</TableCell>
                                        <TableCell align="right">{(row.amount / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>)}
            <br />

            <Dialog maxWidth={"sm"} fullWidth fullScreen={fullScreen} onClose={handleClose} open={payoutDialogOpen} style={{ bottom: fullScreen ? 0 : 'unset', top: fullScreen ? 0 : '10%' }}>
                <DialogTitle onClose={handleClose} variant={fullScreen ? "secondary" : "default"} >
                    <Typography variant="h5">Auszahlung</Typography>
                </DialogTitle>
                <DialogContent>
                    <br /><br /><br />
                    <br /><br /><br />
                    <Grid container style={{ justifyContent: 'center', alignContent: 'center' }}>
                        <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center' }}>
                            <TextField
                                label="Betrag"
                                error={payoutValueError ? true : false}
                                value={payoutValue}
                                type="number"
                                onChange={handlePayoutValue}
                                inputProps={{
                                    min: "0",
                                    step: "0.01",
                                    lang: "de-DE",
                                    style: { textAlign: 'right' }
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                }}
                                variant="outlined"
                            />
                            <FormHelperText id="standard-weight-helper-text" error={payoutValueError ? true : false}>{" " + payoutValueError}</FormHelperText>

                            <Typography variant="body2" style={{ color: '#888' }}>Trainerio-Guthaben: {(wallet.available ? wallet.available : 0).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })} verfügbar</Typography>
                            <br /><br />
                            <Button disabled={payoutValueError || payoutValue <= 0 ? true : false} onClick={() => handleClaimPayout()}>{isProcessing ? <CircularProgress size={24} /> : "Betrag Auszahlen"}</Button>
                            <br /><br />
                            {isClaimSuccessful && <Typography>Die Auszahlung ist veranlasst worden, du solltest in Kürze eine Email mit dem Gutschein-Code erhalten.</Typography>}
                        </Grid>


                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog maxWidth={"sm"} fullWidth fullScreen={fullScreen} onClose={handleClose} open={termsDialogOpen} style={{ bottom: fullScreen ? 0 : 'unset', top: fullScreen ? 0 : '10%' }}>
                <DialogTitle onClose={handleClose} variant={fullScreen ? "secondary" : "default"} titlePosition="top">

                </DialogTitle>
                <DialogContent>

                    {parse(terms)}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Refferals;
