
import { useEffect } from 'preact/hooks';
import { CssBaseline, useMediaQuery } from '@material-ui/core';
import { createTheme, MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import ErrorBoundary from '/components/common/errorBoundary';
import SocketIo from '/components/common/socketIo';
import '@fontsource/inter';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { Router, Route, Switch } from 'wouter-preact';
import { useUserState } from '/lib/states/user';
import { useContentState } from '/lib/states';
import Routes from '/lib/routes';
import { SnackbarProvider } from 'notistack';
import { useLocation } from 'wouter-preact';
import SwWatcher from './swWatcher';
import { createNanoEvents } from 'nanoevents'

const emitter = createNanoEvents();

const themeLight = createTheme({
	overrides: {
		MuiCssBaseline: {
			'@global': {
				html: {
					height: '100%',
				},
				body: {
					height: '100%',
				},
			},
		},
		MuiLink: {
			root: {
				cursor: 'pointer',
				color: '#D6C837'
			},
		},
		MUIDataTable: {
			responsiveScroll: {
				overflowY: 'scroll',
				maxHeight: 'none',
			},
		},
		MuiTimelineItem: {
			missingOppositeContent: {
				"&:before": {
					display: "none"
				}
			}
		},
		MuiListItem: {
			root: {
				paddingTop: 0,
				paddingBottom: 0,

			},
			gutters: {
				paddingLeft: 8,
			},
		},
		MuiListItemText: {
			primary: {
				fontSize: '0.9rem',
			},
		},
		MuiListItemAvatar: {
			root: {
				minWidth: 50,
			},
		},

	},
	palette: {
		headerGradient: {
			primary: 'linear-gradient(315deg, #8BDDCF 0%, #2EA28F 24%, #3CC7B1 100%)',
			secondary: 'linear-gradient(315deg, #955FDF 0%, #6425B9 24%, #955FDF 100%)',
		},

		gradient: {
			primary: 'linear-gradient(to right bottom, #8BDDCF, #2EA28F)',
			secondary: 'linear-gradient(to right bottom, #4900E3, #9B51E0)',
			tertiary: 'linear-gradient(to right bottom, #DEDCBD, #E0D56A)',
			gray: 'linear-gradient(to right bottom, #C7C8D9, #8F90A6)',
		},
		buttonGradient: {
			primary: 'linear-gradient(to right bottom, #4900E3, #9B51E0)',
			secondary: 'linear-gradient(to right bottom, #4900E3, #9B51E0)',
			gray: 'linear-gradient(to right bottom, #C7C8D9, #8F90A6)',
		},
		background: {
			default: '#FAFAFC',
		},
		primary: {
			light: '#8BDDCF',
			main: '#3CC7B1',
			dark: '#2EA28F',
		},
		secondary: {
			light: '#BE9DEB',
			main: '#955FDF',
			dark: '#6425B9',
		},
		tertiary: {
			light: '#DEDCBD',
			main: '#E0D56A',
			dark: '#D6C837',
		},
		gray: {
			light: '#EBEBF0',
			main: '#C7C8D9',
			dark: '#8F90A6',
		},
		type: 'light',
	},
	typography: {
		h1: {
			fontFamily: [
				'Inter',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		h2: {
			fontFamily: [
				'Inter',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		h4: {
			fontFamily: [
				'Inter',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		h5: {
			fontFamily: [
				'Inter',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		h6: {
			fontFamily: [
				'Inter',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		fontFamily: [
			'Inter',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		subtitle2: {
			color: '#3CC7B1',
			fontSize: '1rem',
		},
		useNextVariants: true,
	},
});


const themeDark = createTheme({
	overrides: {
		MuiCssBaseline: {
			'@global': {
				html: {
					height: '100%',
				},
				body: {
					height: '100%',
				},
			},
		},
		MuiLink: {
			root: {
				cursor: 'pointer',
				color: '#D6C837'
			},
		},
		MUIDataTable: {
			responsiveScroll: {
				overflowY: 'scroll',
				maxHeight: 'none',
			},
		},
		MuiTimelineItem: {
			missingOppositeContent: {
				"&:before": {
					display: "none"
				}
			}
		},
		MuiListItem: {
			root: {
				paddingTop: 0,
				paddingBottom: 0,

			},
			gutters: {
				paddingLeft: 8,
			},
		},
		MuiListItemText: {
			primary: {
				fontSize: '0.9rem',
			},
		},
		MuiListItemAvatar: {
			root: {
				minWidth: 50,
			},
		},

	},
	palette: {
		headerGradient: {
			primary: 'linear-gradient(315deg, #8BDDCF 0%, #2EA28F 24%, #3CC7B1 100%)',
			secondary: 'linear-gradient(315deg, #955FDF 0%, #6425B9 24%, #955FDF 100%)',
		},

		gradient: {
			primary: 'linear-gradient(to right bottom, #8BDDCF, #2EA28F)',
			secondary: 'linear-gradient(to right bottom, #4900E3, #9B51E0)',
			tertiary: 'linear-gradient(to right bottom, #DEDCBD, #E0D56A)',
			gray: 'linear-gradient(to right bottom, #C7C8D9, #8F90A6)',
		},
		buttonGradient: {
			primary: 'linear-gradient(to right bottom, #4900E3, #9B51E0)',
			secondary: 'linear-gradient(to right bottom, #4900E3, #9B51E0)',
			gray: 'linear-gradient(to right bottom, #C7C8D9, #8F90A6)',
		},
		primary: {
			light: '#424242',
			main: '#3CC7B1',
			dark: '#2EA28F',
		},
		secondary: {
			light: '#BE9DEB',
			main: '#955FDF',
			dark: '#6425B9',
		},
		tertiary: {
			light: '#DEDCBD',
			main: '#E0D56A',
			dark: '#D6C837',
		},
		gray: {
			light: '#EBEBF0',
			main: '#C7C8D9',
			dark: '#8F90A6',
		},
		type: 'dark',
	},
	typography: {
		h1: {
			fontFamily: [
				'Inter',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		h2: {
			fontFamily: [
				'Inter',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		h4: {
			fontFamily: [
				'Inter',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		h5: {
			fontFamily: [
				'Inter',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		h6: {
			fontFamily: [
				'Inter',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		fontFamily: [
			'Inter',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		useNextVariants: true,
	},
});

const useStyles = makeStyles((theme) => {
	return ({
		app: {
			background: theme.palette.background.default,
			alignSelf: 'center',
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			width: '100%',
			[theme.breakpoints.up('xs')]: {
				maxWidth: '760px',
			},
			position: 'relative',
			marginBottom: '20px',
			padding: 5,
		},
		content: {
			backgroundColor: theme.palette.background.default,
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
		},
		footer: {
			display: 'block',
		},

		header: {
			position: 'relative',
			minHeight: '65px',
			alignSelf: 'center',
		},

		phoneScreen: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'hidden',
		},

	})
});

const WouterBridge = () => {
	const [location, setLocation] = useLocation();

	useEffect(() => emitter.on('navigate', loc => setLocation(loc)));
}

const App = () => {
	const classes = useStyles();
	const { user, isLoggedIn, userTheme, verifySession } = useUserState();
	const { getContents } = useContentState();
	const prefersDarkMode = (userTheme === 'dark' && useMediaQuery('(prefers-color-scheme: dark)')) || userTheme === 'dark';

	useEffect(() => {
		verifySession();
		getContents();
	}, []);

	return (
		<SnackbarProvider maxSnack={3}>
			<MuiThemeProvider theme={prefersDarkMode ? themeDark : themeLight}>
				<CssBaseline />
				<ErrorBoundary>
					{2 !== 2 && <div style="position: fixed; bottom: 0; padding: 10px; z-Index: 1999" >
						<img src="/assets/images/cookie_config.png" style="cursor: pointer"
							onClick={() => klaro.show(klaroConfig, true)} />
					</div>}
					<I18nextProvider i18n={i18n}>
						<SocketIo />
						<Router>
							<Routes isLoggedIn={isLoggedIn} />
							<WouterBridge />
						</Router>
						<SwWatcher />
					</I18nextProvider>
				</ErrorBoundary>
			</MuiThemeProvider>
		</SnackbarProvider>
	)
};

export default App;
