import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { Fab } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.secondary.main,
        margin: theme.spacing(1)
    },
});

const getIcon = (variant) => {
    switch (variant) {
        case "back":
            return <ArrowBackIcon />;
        case "close":
            return <CloseIcon />;
        case "user":
            return <PersonOutlineIcon />;
        case "up":
            return <ArrowUpIcon />;
        case "restart":
            return <SettingsBackupRestoreIcon />;
        case "new":
            return <AddIcon />;
        case "home":
        default:
            return <HomeOutlinedIcon />;
    }
}
export const HeaderIcon = withStyles(styles)((props) => {
    const { disabled = false, variant, position, children, classes, onClick, ...other } = props;

    if (disabled) {
        return <></>;
    }

    return (

        <Fab disabled={disabled} size="small" className={classes.root} onClick={onClick ? onClick : null} >
            {getIcon(variant)}
        </Fab>
    )

});
