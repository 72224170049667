import { Typography } from "@material-ui/core";
import { useErrorBoundary } from "preact/hooks";
import { Link } from "wouter-preact";

const ErrorBoundary = (props) => {
    const [error, resetError] = useErrorBoundary();
    if (error) {
        return (
            <div style={{ textAlign: 'center', marginTop: '100px' }}>
                <Typography variant="h2">Ooops</Typography>
                <Typography variant="body1">Hier ist was schief gelaufen, <br /><br />
                    {process.env.NODE_ENV === 'development' && error.message}<br /><br />
                <Link to="/" onClick={resetError}>Nochmal versuchen</Link> </Typography>


            </div>
        );
    } else {
        return props.children
    }
};
export default ErrorBoundary;