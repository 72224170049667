/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'preact/hooks';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Typography,
    Fab,
    CardContent,
    Card,
    Box,
    Dialog,
    IconButton,
    ListItemText,
    ListItem,
    List,
    Slide,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useUserState } from '/lib/states';
import { HeaderIcon, HeaderIconGroup, Button, DialogTitle } from '/components/common';

import { forwardRef } from "preact/compat";
import { useLocation } from 'wouter-preact';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { orderService } from '/services';
import * as dayjs from 'dayjs';
import { saveAs } from 'file-saver';

const useStyles = makeStyles((theme) => ({
    cardWithoutBackground: {
        backgroundColor: theme.palette.background.default,
        marginTop: '10px'
    },
    cardWithBackground: {
        borderTop: '1px solid #EBEBF0',
        borderBottom: '1px solid #EBEBF0',
        marginTop: '10px'
    },
}));

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);


const InvoiceOverview = (props) => {
    const theme = useTheme();

    const classes = useStyles();
    const { t } = useTranslation();
    const { user, userTheme, logout, setTheme } = useUserState();

    const [sendingDoneDialogOpen, setSendingDoneDialogOpen] = useState(false);
    const [certSendingDoneDialogOpen, setCertSendingDoneDialogOpen] = useState(false);

    const [orders, setOrders] = useState([]);

    const initTableData = async () => {
        const dbOrders = await orderService.getOrders();
        if (dbOrders) {
            setOrders(dbOrders);
        }

    }

    const prepareSaveCertificateAs = async (orderId, courseName) => {
        const invoice = await orderService.getCertificate(orderId);
        saveAs("data:application/pdf;base64," + invoice, "Zertifikat Trainerio " + courseName + ".pdf")
    }
    
    const prepareSaveInvoiceAs = async (orderId, courseName) => {
        const invoice = await orderService.getInvoice(orderId);
        saveAs("data:application/pdf;base64," + invoice, "Rechnung Trainerio " + courseName + ".pdf")
    }

    useEffect(() => {
        initTableData();
    }, []);

    const [location, setLocation] = useLocation();
    return (
        <>
            <HeaderIconGroup>
                <HeaderIcon variant="back" onClick={() => setLocation('/mitgliederbereich/einstellungen')} />
            </HeaderIconGroup>
            <Box style={{ padding: theme.spacing(1) }} elevation={0}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    Rechnungen und Zertifikate
                </Typography>
                <br />
            </Box>
            {orders.map((order, index) =>
                order.items.map((item) =>
                    <Card elevation={0} className={(index % 2 === 0) ? classes.cardWithBackground : classes.cardWithoutBackground} square>
                        <CardContent style={{ padding: theme.spacing(1) }}>
                            <Typography variant="subtitle1"><b>Kurs: {item.name} </b></Typography>
                            <Typography variant="subtitle2">Rechnung</Typography>
                            <br />
                            <Typography variant="body2">
                                <b>Rechnungsbetrag:</b> {(order.total_amount / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}<br />
                                <b>Bezahlt am:</b> {dayjs(order.paid_on).format('DD.MM.YYYY')}<br />
                            </Typography>
                            <br />
                            <Button
                                variant="outlined"
                                color="secondary"
                                icon="email"
                                style={{ width: '48%', margin: '2px' }}
                                onClick={async () => {
                                    const result = await orderService.sendInvoice(order.id);
                                    if (result.status === 'success') {
                                        setSendingDoneDialogOpen(true);
                                    }
                                }}
                            >Als E-Mail an mich schicken
                            </Button>
                            <Button
                                variant="outlined"
                                icon="download"
                                color="secondary"
                                style={{ width: '48%', margin: '2px' }}
                                onClick={() => { prepareSaveInvoiceAs(order.id, item.name) }}
                            >
                                Als PDF Speichern
                            </Button>
                            <br />
                        </CardContent>
                        {order.finished_on && <CardContent style={{ padding: theme.spacing(1) }}>
                            <Typography variant="subtitle2"><b>Abschlusszertifikat</b></Typography>
                            <br />
                            <Typography variant="body2">
                                <b>Kurs abgeschlossen am:</b> {dayjs(order.finished_on).format('DD.MM.YYYY')}<br />
                            </Typography>
                            <br />
                            <Button
                                variant="outlined"
                                color="secondary"
                                icon="email"
                                style={{ width: '48%', margin: '2px' }}
                                onClick={async () => {
                                    const result = await orderService.sendCertificate(order.id);
                                    if (result.status === 'success') {
                                        setCertSendingDoneDialogOpen(true);
                                    }
                                }}
                            >Als E-Mail an mich schicken
                            </Button>
                            <Button
                                variant="outlined"
                                icon="download"
                                color="secondary"
                                style={{ width: '48%', margin: '2px' }}
                                onClick={() => { prepareSaveCertificateAs(order.id, item.name) }}
                            >
                                Als PDF Speichern
                            </Button>
                            <br />
                        </CardContent>
                        }
                    </Card>
                )
            )}<br />
            <Dialog
                open={sendingDoneDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setSendingDoneDialogOpen(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Rechnung versandt</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Die Rechnung wurde erfolgreich an deine Email-Adresse geschickt.
              </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSendingDoneDialogOpen(false)} color="primary">OK</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={certSendingDoneDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setCertSendingDoneDialogOpen(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Zertifikat versandt</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Das Zertifikat wurde erfolgreich an deine Email-Adresse geschickt.
              </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCertSendingDoneDialogOpen(false)} color="primary">OK</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default InvoiceOverview;
