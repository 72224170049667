import React, { useState, useEffect } from 'react';
import {
    makeStyles, useTheme,
} from '@material-ui/core/styles';
import {
    useMediaQuery,
    Avatar,
    Button,
    Dialog, Grow, Paper, Popper, DialogContent, IconButton,
} from '@material-ui/core';

import HelpDialog from './dialog';


import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import { DialogTitle, HeaderIcon, HeaderIconGroup } from '/components/common';
import './style.css';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 350,
        zIndex: 2000,
        marginLeft: 5,
    },
    paper: {
        marginTop: 10,
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    conversationIcon: {
        color: 'white',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    pulse: {
        background: "black",
        borderRadius: "50%",
        margin: 5,
        boxShadow: "0 0 0 0 rgba(0, 0, 0, 1)",
        transform: "scale(1)",
        animation: "$pulse 2s"
    },
    "@keyframes pulse": {
        "0%": {
            transform: "scale(0.95)",
            boxShadow: "0 0 0 0 rgba(0, 0, 0, 0.7)"
        },
        "70%": {
            transform: "scale(1)",
            boxShadow: "0 0 0 5px rgba(0, 0, 0, 0)"
        },
        "100%": {
            transform: "scale(0.95)",
            boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)"
        }
    }
}));


export default function Help(props) {
    let timer;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [resetConversation, setResetConversation] = useState(false);
    const [stepBack, setStepBack] = useState(false);
    const [answers, setAnswers] = useState([]);

    const [currentIcon, setCurrentIcon] = useState(0);
    const classes = useStyles();
    const icons = [
        <Avatar className={classes.pulse} style={{ backgroundColor: '#9B51E0', color: '#FFFFFF' }}>?</Avatar>,
        <Avatar className={classes.pulse} style={{ backgroundColor: '#9B51E0', color: '#FFFFFF' }}><PhoneIcon /></Avatar>,
        <Avatar className={classes.pulse} style={{ backgroundColor: '#9B51E0', color: '#FFFFFF' }}><MailIcon /></Avatar>
    ]
    const [icon, setIcon] = useState(icons[currentIcon]);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const changeIcon = () => {
        setCurrentIcon(current => current < 2 ? current + 1 : 0);
        timer = setTimeout(() => changeIcon(), 5000);
    }

    useEffect(() => {
        timer = setTimeout(() => changeIcon(), 5000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>

            <Button
                variant="outlined"
                color="primary"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                disableRipple
                style={
                    {
                        margin: 3,
                        color: '#9B51E0',
                        borderRadius: 45,
                        padding: 0,
                        borderColor: '#DEDFDE',
                        backgroundColor: '#FFFFFF'
                    }
                }
            >
                {icons[currentIcon]}
                <span style={{ paddingLeft: 10, paddingRight: 10 }}>HILFE / FRAGEN / KONTAKT</span>
            </Button>
            {matches ? (
                <Dialog open={open} fullScreen onClose={handleToggle}>
                    <DialogTitle variant={"secondary"} title={"Hilfe, Fragen & Kontakt"} titlePosition="top">
                        <HeaderIconGroup position="flex-end">
                            <HeaderIcon variant="up" onClick={() => setStepBack(true)} disabled={answers.length === 0} />
                            <HeaderIcon variant="restart" onClick={() => setResetConversation(true)} />
                            <HeaderIcon variant="close" onClick={handleToggle} />
                        </HeaderIconGroup>
                    </DialogTitle>
                    <DialogContent style={{
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        overflowY: 'scroll',
                        flex: 1
                    }}>
                        <HelpDialog handleClose={handleToggle} resetConversation={resetConversation} resetDone={() => setResetConversation(false)} stepBack={stepBack} stepBackDone={() => setStepBack(false)} setAnswers={(answers) => setAnswers(answers)} />
                    </DialogContent>
                </Dialog>

            )
                : (
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="top" transition disablePortal style={{ margin: 10, zIndex: 10000 }}>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'top' ? 'center bottom' : 'center top' }}
                            >
                                <Paper style={{ width: 440, height: 600, flexGrow: 1 }}>
                                    <DialogTitle variant={"secondary"} title={"Hilfe, Fragen & Kontakt"} titlePosition="top">
                                        <div style={{ position: 'absolute', right: 0 }}>
                                            <HeaderIcon variant="up" onClick={() => setStepBack(true)} disabled={answers.length === 0} />
                                            <HeaderIcon variant="restart" onClick={() => setResetConversation(true)} />
                                            <HeaderIcon variant="close" onClick={handleToggle} />
                                        </div>
                                    </DialogTitle>
                                    <DialogContent style={{
                                        position: 'absolute', bottom: 3,
                                        overflowY: 'auto',
                                        display: 'flex',
                                        flexDirection: 'column-reverse',
                                        flex: 1,
                                        maxHeight: 540,
                                        height: '100%',
                                        width: '100%'
                                    }}>
                                        <HelpDialog handleClose={handleToggle} resetConversation={resetConversation} resetDone={() => setResetConversation(false)} stepBack={stepBack} stepBackDone={() => setStepBack(false)} setAnswers={(answers) => setAnswers(answers)} />
                                    </DialogContent>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                )
            }
        </>
    );
}
