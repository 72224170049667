import { createState, useState } from '@hookstate/core';
import { contentService } from '/services';
import { useUserState } from '/lib/states/user';
import { Persistence } from '@hookstate/persistence';

const contentState = createState({
    contents: []
})

export function useContentState() {
    const state = useState(contentState);
    if (typeof window !== 'undefined') {
        state.attach(Persistence('trainerio-public-content'));
    }
    const userState = useUserState();

    return ({
        getContents() {
            return contentService.getPublicContents()
                .then((contents) => {
                    state.set(oldState => ({
                        ...oldState,
                        contents
                    }));
                    return "success";
                })
                .catch((error) => {
                    return "error";
                });
        },
        getContent(contentId) {
            const contents = JSON.parse(JSON.stringify(state.contents.get()));
            const content = contents.find(item => contentId === item.id || contentId === item.slug);
            console.log(content);
            if (content) {
                console.log("asdsss");
                if (userState.loggedIn) {
                    contentService.getContent(contentId)
                        .then((freshContent) => {

                            state.set(oldState =>
                            ({
                                ...oldState,
                                contents: [
                                    ...state.contents.get(),
                                    freshContent
                                ],
                            }));
                            return content;
                        })
                        .catch((error) => {
                            return {};
                        });
                }
                return content;
            } else {
                if (userState.loggedIn) {
                    return contentService.getContent(contentId)
                        .then((freshContent) => {

                            state.set(oldState =>
                            ({
                                ...oldState,
                                contents: [
                                    ...state.contents.get(),
                                    freshContent
                                ],
                            }));
                            return content;
                        })
                        .catch((error) => {
                            return {};
                        });
                }
                return {};
            }
        },
    })
}