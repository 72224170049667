import React, { useState, useEffect } from 'react';
import { userService } from '/services';
import {
    useMediaQuery, Fab, IconButton, Typography, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, FormHelperText, Link, Box
} from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import ReactInputVerificationCode from 'react-input-verification-code';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useTheme } from '@material-ui/core/styles';
import { DialogTitle, Button } from '/components/common';
import { useUserState } from '/lib/states';
import { HeaderIcon, HeaderIconGroup } from '/components/common';
import { useLocation } from 'wouter-preact';

export default function DeleteAccount(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [username, setUsername] = useState('');
    const [errors, setErrors] = useState({});
    const [showSuccessMessage, setSuccessMessage] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [verificationCode, setVerificationCode] = React.useState(0);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState('');

    const [location, setLocation] = useLocation();
    const { user, logout } = useUserState();
    const [counter, setCounter] = React.useState(10);
    const handleClose = () => {
        setUsername('');
        setSuccessMessage(false);
        props.close();
    };
    const handleChange = (event) => {
        setUsername(event.target.value);
        setErrors({});
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setErrors({});
    };
    const submitMailAddress = () => userService.changePassword().then(() => {
        setSuccessMessage(true);
    }).catch((err) => {
        setErrors([{ field: 'username', message: err.message }]);
    });

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const verifyCode = () => userService.validatePasswordLink({ verificationToken: verificationCode }).then((validationResult) => {
        if (validationResult.status === 'success') {
            setErrors({});
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            setErrors({ ...errors, verificationCode: 'Der eingegbene Code ist nicht korrekt' });
        }
        setSuccessMessage(true);
    }).catch((err) => {
        setErrors({ ...errors, verificationCode: 'Der eingegbene Code ist nicht korrekt' });
    });

    const deleteAccount = () => userService.deleteAccount({ verificationToken: verificationCode }).then(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        logout();
        setSuccessMessage(true);
    }).catch((err) => {
        const error = { newPassword: 'Der eingegbene Code ist nicht korrekt' };
        setErrors(error);
    });

    useEffect(() => {
        setUsername(props.username);
    }, [props.username]);

    const { t } = useTranslation();

    const handleNext = () => {
        switch (steps[activeStep].slug) {
            case 'STEP1':
                submitMailAddress();
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setCounter(10);
                break;
            case 'STEP2':
                verifyCode();
                break;
            case 'STEP3':
                deleteAccount();
                break;
            case 'STEP4':
                setLocation('/');
                break;
            default:
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
        }
    };

    const handleBack = () => {
        setCounter(10);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const steps = [{
        slug: 'STEP1',
        component:
            <>
                Natürlich können wir Deinen Account löschen, aber bist Du Dir wirklich sicher?! Alle Deine Kurse, der Fortschritt, Zertifikate werden zusammen mit Deinen persönlichen Daten mitgelöscht. Zu Deiner Info: Wir werden trotzdem Rechnungen behalten müssen, damit wir diese rechtsmäßig dem Finanzamt vorlegen können.
                <br /><br />
                Solltest Du Dich doch dafür entscheiden Deinen Account löschen zu wollen, müssen wir aus Sicherheitsgründen bestätigen, dass Du auch der Eigentümer dieses Accounts bist. Wir werden im nächsten Schritt eine E-Mail zur Verifizierung an <b>{user.username}</b> senden.                <br />
                <br />
                <br />
                <br />
            </>,
        title: 'Account löschen',
        buttonText: 'E-Mail Schicken',
    },
    {
        slug: 'STEP2',
        component:
            <>
                <br /><br />
                Bitte öffne die E-Mail, die wir soeben an Dich versandt haben und gib die darin enthaltene vierstellige Zahl hier ein:
                {' '}
                <b>{username}</b>
                {' '}
gesendet haben.
                <br />
                {' '}
                <br />
                <div align="center">
                    <ReactInputVerificationCode onChange={(value) => setVerificationCode(value)} value={verificationCode} autoFocus />
                    <FormHelperText>
                        {errors.verificationCode && errors.verificationCode}
                    </FormHelperText>
                </div>
                <br />
                <br />
                {counter === 0
                    ? (
                        <div>
                            E-Mail nicht angekommen oder zu spät gewesen? Bitte überprüfe auch Deinen Spam-Ordner. Ansonsten:
                            <Link underline={'hover'} onClick={() => {
                                submitMailAddress();
                                setCounter(10);
                            }}
                            > Erneut zusenden</Link>.
                        </div>
                    )

                    : (
                        <div>
                            Bitte warte noch
                            {' '}
                            {counter}
                            {' '}
                Sekunden auf den Erhalt des Codes.
                        </div>
                    )}
                <br />
                <br />
            </>,
        title: 'Account löschen',
        buttonText: 'Weiter',
    },
    {
        slug: 'STEP3',
        component:
            <>
                Achtung!<br /><br />
                Dies ist der letzte Punkt, an dem Du Deine Meinung noch ändern kannst (klicke oben links auf zurück). Ansonsten werden wir hiermit Deinen Account  unwiederruflich löschen..
                <br />
                <br />
            </>,
        title: 'Account löschen',
        buttonText: 'Account wirklich löschen',
    },
    {
        slug: 'STEP4',
        component:
            <>
                <br />
                <div align="center">
                    Wir haben Deinen Account gelöscht und eine letzte, abschließende Mail an die hinterlegte E-Mail Adresse geschickt mit der Bestätigung diese Fakts. Hoffentlich bis bald mal wieder!
                </div>
                <br />
            </>,
        title: 'Account gelöscht!',
        buttonText: 'OK',
    }];

    React.useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    return (
        <Box m={2} >
            <HeaderIconGroup>
                <HeaderIcon variant="back" onClick={() => history.back()} />
            </HeaderIconGroup>
            <Typography variant="h6">{steps[activeStep].title}</Typography>
            <br />
            <div>{steps[activeStep].component}</div>
            <div style={{ float: 'right' }}>
                <Button onClick={handleNext} color="primary" fullWidth variant="next" >
                    {steps[activeStep].buttonText}
                </Button>
            </div>
        </Box>
    );
}
